import React, { useEffect, useRef, useState, RefObject } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Button, Classes, Popover } from '@blueprintjs/core';
import { useOpenInNewTab } from 'api/history-hooks';
import { User } from 'api/user-types';
import { Table, Td, TdTop, Th } from '../client-page.styles';
import { Pc, Lp, Option } from '../api/client-types';
import { PcPopover } from './PcPopover';
import { ChangeCardOptionAlert, ChangeCardOptionAlertHandles } from './ChangeCardOptionAlert';
import { clientApi } from '../api/client';
import * as _ from 'lodash';

export const PcSection = ({ user, data, lpCards }: { user: User; data: Pc[]; lpCards: Lp[] }) => {
  const { mnemocode: clientMnemocode } = useParams();

  const [cards, setCards] = useState<Pc[]>([]);

  const refChangeCardOptionAlert = useRef() as RefObject<ChangeCardOptionAlertHandles>;

  useEffect(() => {
    setCards(data);
  }, [data]);

  const openNewTab = useOpenInNewTab();

  const isActive = (startDate: string, finishDate: string): boolean => {
    return moment().isAfter(startDate) && (!finishDate || moment().isBefore(finishDate));
  };

  const handleShowBalance = (card: Pc) => {
    const cardMnemocode = card?.mnemocode;
    openNewTab(`/client/balance/${clientMnemocode}/${cardMnemocode}`);
  };

  const handleShowOptions = (card: Pc) => {
    const cardMnemocode = card?.mnemocode;
    openNewTab(`/client/options/${clientMnemocode}/${cardMnemocode}`);
  };

  const handleChangeCardOptionAlert = (cardMnemocode: string) => {
    const productOptionId = _.chain(cards)
      .find({ mnemocode: cardMnemocode })
      .get('options')
      .find(['finishDate', null])
      .get('productOptionId')
      .value();

    refChangeCardOptionAlert?.current?.open(cardMnemocode, productOptionId);
  };

  const handleChangeCardOptionAlertOnClose = async (confirm: boolean, mnemocode: string) => {
    if (confirm) {
      const cardData = await clientApi.getCardData(clientMnemocode);
      setCards(cardData?.cards?.pc);
    }
  };

  return (
    <div style={{ margin: '7px' }}>
      <ChangeCardOptionAlert
        ref={refChangeCardOptionAlert}
        onClose={(confirm: boolean, mnemocode: string) => handleChangeCardOptionAlertOnClose(confirm, mnemocode)}
      />
      <Table>
        <thead>
          <tr>
            <Th>Статус</Th>
            <Th>Изображение</Th>
            <Th>Номер карты</Th>
            <Th>Тип карты</Th>
            <Th>Валюта карты</Th>
            <Th>Опции</Th>
            <Th>Привязки</Th>
            <Th>*</Th>
          </tr>
        </thead>
        <tbody>
          {cards.map((card) => (
            <tr key={card.mnemocode}>
              <Td>
                <div>
                  <b>
                    <i>{card.status.name}</i>
                  </b>
                </div>
              </Td>
              <Td>
                <img style={{ maxWidth: '150px' }} alt="img" src={card.image} />
              </Td>
              <Td>
                <p>{card.number || 'отсутствует'}</p>
              </Td>
              <Td>
                <p>{card.type}</p>
              </Td>
              <Td>
                <p>{card.currency.name}</p>
              </Td>
              <TdTop>
                {card.options &&
                  card.options.map((option: Option, i) => (
                    <>
                      <Table>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: 'right' }}>
                              {isActive(option.startDate, option.finishDate) ? (
                                <b>Активная опция:&nbsp;</b>
                              ) : (
                                <b>Опция:&nbsp;</b>
                              )}
                            </td>
                            <td>{option.name}</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'right' }}>
                              <b>Действует с:</b>&nbsp;
                            </td>
                            <td>{option.startDate ? moment(option.startDate).format('DD.MM.YYYY') : '-'}</td>
                          </tr>
                          {option.finishDate && (
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <b>по:</b>&nbsp;
                              </td>
                              <td>{option.finishDate ? moment(option.finishDate).format('DD.MM.YYYY') : '-'}</td>
                            </tr>
                          )}
                          <tr style={{ fontSize: '85%' }}>
                            <td style={{ textAlign: 'right' }}>productOptionId:&nbsp;</td>
                            <td>{option.productOptionId}</td>
                          </tr>
                        </tbody>
                      </Table>
                      {i + 1 !== card.options.length && <hr />}
                    </>
                  ))}
              </TdTop>
              <Td>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {card.programs &&
                    card.programs.map((program, i) => (
                      <div key={program.productOptionId}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ cursor: 'pointer' }}>
                            <img style={{ width: '100px' }} alt={program.image} src={program.image} />
                          </div>
                          <div style={{ flexGrow: 1, margin: '0 0 0 5px' }}>
                            <div>{program.name}</div>
                            <Table>
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'right' }}>
                                    <b>Действует с:</b>&nbsp;
                                  </td>
                                  <td>{program.startDate ? moment(program.startDate).format('DD.MM.YYYY') : '-'}</td>
                                </tr>
                                {program.finishDate && (
                                  <tr>
                                    <td style={{ textAlign: 'right' }}>
                                      <b>по:</b>&nbsp;
                                    </td>
                                    <td>
                                      {program.finishDate ? moment(program.finishDate).format('DD.MM.YYYY') : '-'}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {i + 1 !== card.programs.length && <hr />}
                      </div>
                    ))}
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    {user?.role === 'SUDO' && (
                      <Button
                        small
                        outlined
                        intent="success"
                        onClick={() => handleChangeCardOptionAlert(card?.mnemocode)}
                      >
                        Сменить опцию
                      </Button>
                    )}
                    <Button small outlined intent="success" onClick={() => handleShowBalance(card)}>
                      Посмотреть баланс
                    </Button>
                    <Button small outlined intent="success" onClick={() => handleShowOptions(card)}>
                      История настройки карты
                    </Button>
                  </div>
                </div>
              </Td>
              <Td>
                <Popover content={<PcPopover card={card} />} popoverClassName={Classes.POPOVER_CONTENT_SIZING}>
                  <Button icon="cog" />
                </Popover>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
