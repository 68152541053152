import { partnerApi } from 'api/partner';
import { PartnerListResponse, PartnerResponse, Partner } from 'api/partner-types';
import {
  createUseEntityList,
  createUseEntity,
  createUseEntityAdd,
  createUseEntityUpdate,
  createUseEntityDelete,
} from 'api/base-hooks';

export const usePartnerList = createUseEntityList<PartnerListResponse>('partner', partnerApi.getList);
export const usePartner = createUseEntity<PartnerResponse>('partner', partnerApi.getItem);
export const usePartnerAdd = createUseEntityAdd<Partner>('partner', partnerApi.addItem);
export const usePartnerUpdate = createUseEntityUpdate<Partner>('partner', partnerApi.editItem);
export const usePartnerDelete = createUseEntityDelete<number>('partner', partnerApi.deleteItem);
