import { BaseApi } from 'api/base';
import * as Types from 'api/partner-types';

class PartnerApi extends BaseApi {
  getList = async (): Promise<Types.PartnerListResponse> => {
    const result = await this.fetch<Types.PartnerListResponse>('/partner');
    return result;
  };

  getItem = async (id: number): Promise<Types.PartnerResponse> => {
    const result = await this.fetch<Types.PartnerResponse>(`/partner/${id}`);
    return result;
  };

  addItem = async (data: Types.Partner): Promise<Types.Partner> => {
    const result = await this.fetch<Types.AddPartnerResponse>(`/partner`, { method: 'post', data });
    return { id: result.id, ...data };
  };

  editItem = async (id: number, data: Types.Partner): Promise<Types.Partner> => {
    await this.fetch<Types.PartnerResponse>(`/partner/${id}`, { method: 'put', data });
    return data;
  };

  deleteItem = async (id: number): Promise<number> => {
    await this.fetch<Types.PartnerResponse>(`/partner/${id}`, { method: 'delete' });
    return id;
  };
}

export const partnerApi = new PartnerApi();
