import React from 'react';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { InputField, SwitchField, TextAreaField, SumbitButton } from 'components/ui/formik-controls';
import { Partner } from 'api/partner-types';
import { PartnerSchema } from './partner-schema';
import * as S from './partner-form.styles';
import { getUserRole } from '../../../selectors/auth';

const emptyData = {
  mnemocode: '',
  isActive: true,
  apiKey: '',
  domainName: '',
  components: {
    name: '',
    banner: '',
    currencyName: '',
    description: '',
    rulesLink: '',
  },
  prcMode: undefined,
  restrictionConversionFrom: null,
  restrictionConversionTo: null,
  restrictionPrecalcFrom: null,
  restrictionPrecalcTo: null,
};

type FormValues = Partner & {
  restrictionConversion: boolean;
  restrictionPrecalc: boolean;
  restrictionConversionFromDateTime: null | string;
  restrictionConversionToDateTime: null | string;
  restrictionPrecalcFromDateTime: null | string;
  restrictionPrecalcToDateTime: null | string;
};

type AfterSubmitHandler = (data?: Partner) => void;
type PartnerFormProps = {
  initialValues?: Partner;
  onSubmit: (user: Partner) => Promise<Partner | undefined>;
  onAfterSubmit?: AfterSubmitHandler;
};
export const PartnerForm = (props: PartnerFormProps) => {
  const { initialValues = emptyData, onSubmit, onAfterSubmit } = props;

  let formInitialValues: FormValues = {
    ...initialValues,
    restrictionConversion: false,
    restrictionPrecalc: false,
    restrictionConversionFromDateTime: null,
    restrictionConversionToDateTime: null,
    restrictionPrecalcFromDateTime: null,
    restrictionPrecalcToDateTime: null,
  };

  if (formInitialValues.restrictionConversionFrom && formInitialValues.restrictionConversionTo) {
    formInitialValues.restrictionConversion = true;
    formInitialValues.restrictionConversionFromDateTime = moment
      .utc(formInitialValues.restrictionConversionFrom)
      .format('YYYY-MM-DD HH:mm:ss');

    formInitialValues.restrictionConversionToDateTime = moment
      .utc(formInitialValues.restrictionConversionTo)
      .format('YYYY-MM-DD HH:mm:ss');
  }

  if (formInitialValues.restrictionPrecalcFrom && formInitialValues.restrictionPrecalcTo) {
    formInitialValues.restrictionPrecalc = true;
    formInitialValues.restrictionPrecalcFromDateTime = moment
      .utc(formInitialValues.restrictionPrecalcFrom)
      .format('YYYY-MM-DD HH:mm:ss');

    formInitialValues.restrictionPrecalcToDateTime = moment
      .utc(formInitialValues.restrictionPrecalcTo)
      .format('YYYY-MM-DD HH:mm:ss');
  }

  const userRole = useSelector(getUserRole);
  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={PartnerSchema}
        onSubmit={async (formValues, { setSubmitting }) => {
          const {
            restrictionConversion,
            restrictionPrecalc,
            restrictionConversionFromDateTime,
            restrictionConversionToDateTime,
            restrictionPrecalcFromDateTime,
            restrictionPrecalcToDateTime,
            ...partner
          } = formValues;

          if (!restrictionConversion || !restrictionConversionFromDateTime || !restrictionConversionToDateTime) {
            partner.restrictionConversionFrom = null;
            partner.restrictionConversionTo = null;
          } else {
            partner.restrictionConversionFrom = moment.utc(restrictionConversionFromDateTime).toISOString();
            partner.restrictionConversionTo = moment.utc(restrictionConversionToDateTime).toISOString();
          }

          if (!restrictionPrecalc || !restrictionPrecalcFromDateTime || !restrictionPrecalcToDateTime) {
            partner.restrictionPrecalcFrom = null;
            partner.restrictionPrecalcTo = null;
          } else {
            partner.restrictionPrecalcFrom = moment.utc(restrictionPrecalcFromDateTime).toISOString();
            partner.restrictionPrecalcTo = moment.utc(restrictionPrecalcToDateTime).toISOString();
          }

          const result = await onSubmit(partner);
          setSubmitting(false);
          if (!result) {
            return;
          }
          if (onAfterSubmit) {
            onAfterSubmit();
          }
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <S.FormWrapper>
              <S.Column>
                <InputField name="mnemocode" label="Мнемокод" />
                <InputField name="apiKey" label="API ключ" />
                <InputField name="domainName" label="Домен" />
                {userRole === 'SUDO' && <InputField name="requestSignatureKey" label="Request Signature Key" />}
                <InputField name="prcMode" label="prcMode" />
                <SwitchField name="isActive" label="Активен" />
                <SwitchField name="restrictionConversion" label="Выключить конвертации" />
                {values.restrictionConversion && (
                  <div>
                    <InputField
                      type="datetime-local"
                      name="restrictionConversionFromDateTime"
                      label="с (UTC, включительно)"
                    />
                    <InputField
                      type="datetime-local"
                      name="restrictionConversionToDateTime"
                      label="по (UTC, включительно)"
                    />
                  </div>
                )}
                <SwitchField name="restrictionPrecalc" label="Выключить предрасчет" />
                {values.restrictionPrecalc && (
                  <div>
                    <InputField
                      type="datetime-local"
                      name="restrictionPrecalcFromDateTime"
                      label="с (UTC, включительно)"
                    />
                    <InputField
                      type="datetime-local"
                      name="restrictionPrecalcToDateTime"
                      label="по (UTC, включительно)"
                    />
                  </div>
                )}
              </S.Column>
              <S.Column>
                <InputField name="components.name" label="Название" />
                <InputField name="components.banner" label="Баннер" />
                <InputField name="components.currencyName" label="Валюта" />
                <InputField name="components.rulesLink" label="Ссылка на правила" />
                <TextAreaField name="components.description" label="Описание" />
              </S.Column>
            </S.FormWrapper>
            <SumbitButton isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </>
  );
};
