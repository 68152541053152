// export type Role = 'SUDO' | 'ADMIN' | 'AGENT' | 'USER' | 'ACL' | 'GPADMIN' | 'GPUSER';

export enum Role {
  SUDO = 'SUDO',
  USER = 'USER',
  AGENT = 'AGENT',
  ADMIN = 'ADMIN',
  ACL = 'ACL',
  GPADMIN = 'GPADMIN',
  GPUSER = 'GPUSER',
}
