import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  DateInputField,
  FileSelectField,
  InputField,
  MultiSelectField,
  MultiSelectFieldItem,
  NumericInputField,
  SelectField,
  SelectFieldOption,
  StaticSelectField,
  SumbitButton,
  SwitchField,
  TagInputField,
  TextAreaField,
} from 'components/ui/formik-controls';
import { ColorEnum, CombinationsPeriodEnum, Icon, Modal, OptionsPeriodEnum, PositionEnum } from 'api/modal-types';
import { PartnerModalSchema } from './partner-modal-schema';
import * as S from './partner-modal-form.styles';
import * as _ from 'lodash';
import { useParams } from 'react-router-dom';
import { createUseProductList } from '../../../../api/product-hooks';
import { createUseProgramList } from '../../../../api/program-hooks';
import moment from 'moment';
import { IconWireFrame, IconWireFrameType } from '../../../ui/icon';
import * as iconsWireframeList from '../../../ui/icon/content/iconsWireframeList';
import { Button, Collapse, ControlGroup, FormGroup } from '@blueprintjs/core';
import { getType } from '../../../../utils';
import { createUseOptionList } from '../../../../api/option-hooks';
import { API_URL_BASE_PATH } from '../../../../api/base';

/* eslint-disable @typescript-eslint/camelcase */
const emptyData = {
  isActive: false,
  title: undefined,
  position: undefined,
  description: undefined,
  icon: undefined,
  button: undefined,
  buttonLink: undefined,
  clients: undefined,
  products: undefined,
  combinations: undefined,
  combinationsPeriod: undefined,
  options: undefined,
  optionsPeriod: undefined,
  path: undefined,
  appearOnEnter: false,
  appearancesCount: 0,
  dateStart: undefined,
  dateEnd: undefined,
  timer: undefined,
  image: undefined,
  order: undefined,
};
/* eslint-enable @typescript-eslint/camelcase */

type IconType = Icon | File | string | undefined;

const colorMap: { [key in ColorEnum]: ColorEnum } = _.omit(ColorEnum, [ColorEnum.brand]);

interface IconProps {
  icon: IconType;
  size: number;
  color?: ColorEnum;
}

const IconPreview = (props: IconProps) => {
  const { icon, size, color } = props;
  const type = getType(icon);
  if (type === 'File') {
    return <img height={`${size}px`} width={`${size}px`} src={URL.createObjectURL(icon as File)} />;
  }
  if (type === 'String') {
    return <img height={`${size}px`} width={`${size}px`} src={icon as string} />;
  }
  if (type === 'Object') {
    return <IconWireFrame icon={(icon as Icon).name as IconWireFrameType} size={size} color={color}></IconWireFrame>;
  }
  return null;
};

const IconCaption = (props: IconProps) => {
  const { icon } = props;
  const type = getType(icon);
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <IconPreview {...props} />
      {type === 'Object' && (icon as Icon)?.name && (
        <span style={{ display: 'flex', padding: '0 0 0 5px' }}>{(icon as Icon)?.name}</span>
      )}
    </span>
  );
};

type AfterSubmitHandler = (data?: Modal) => void;
type ModalFormProps = {
  initialValues?: Modal;
  onSubmit: (modal: Modal) => Promise<Modal | undefined>;
  onAfterSubmit?: AfterSubmitHandler;
};
export const PartnerModalForm = (props: ModalFormProps) => {
  const { partnerId } = useParams<{ [key: string]: string }>();
  const { initialValues = emptyData, onSubmit, onAfterSubmit } = props;
  const data = { ...emptyData, ...initialValues };
  const iconFileInputRef = React.createRef<HTMLInputElement>();

  const positionOptions = Object.keys(PositionEnum).map((key) => ({ value: key, caption: key }));
  const [iconOptions, setIconOptions] = useState<SelectFieldOption<IconType>[]>([]);
  useEffect(() => {
    _.chain(iconsWireframeList)
      .keys()
      .map((icon) => ({
        key: icon,
        value: { name: icon },
        caption: <IconCaption icon={{ name: icon }} size={24} />,
      }))
      .unshift(
        (data.icon && getType(data.icon) === 'String'
          ? { value: data.icon, caption: <IconCaption icon={data.icon} size={24} /> }
          : undefined) as any
      )
      .compact()
      .thru(setIconOptions)
      .value();
  }, [data.icon]);
  const iconColorOptions = _.chain(ColorEnum)
    .keys()
    .map((color) => ({ key: color, value: color, caption: color }))
    .unshift({ value: undefined, caption: <br /> } as any)
    .value();
  const useProdustList = createUseProductList(Number(partnerId));
  const productsItems: MultiSelectFieldItem<string>[] = _.chain(useProdustList())
    .get('data')
    .map((product) => ({ value: product.product_class || '', caption: product.product_class || '' }))
    .value();
  const useProgramList = createUseProgramList(Number(partnerId));
  const combinationsItems: MultiSelectFieldItem<string>[] = _.chain(useProgramList())
    .get('data')
    .map((program) => ({ value: program.productClass || '', caption: program.productClass || '' }))
    .value();

  const useOptionList = createUseOptionList(Number(partnerId));
  const optionsItems: MultiSelectFieldItem<string>[] = _.chain(useOptionList())
    .get('data')
    .map((option) => ({ value: option.mnemocode, caption: option.mnemocode }))
    .value();

  const combinationsPeriodItems: MultiSelectFieldItem<string>[] = Object.keys(CombinationsPeriodEnum).map((key) => ({
    value: key,
    caption: key,
  }));

  const optionsPeriodItems: MultiSelectFieldItem<string>[] = Object.keys(OptionsPeriodEnum).map((key) => ({
    value: key,
    caption: key,
  }));

  const handleSubmit = async (modal: Modal, { setSubmitting }: FormikHelpers<Modal>) => {
    const result = await onSubmit(modal);
    setSubmitting(false);
    if (!result) {
      return;
    }
    if (onAfterSubmit) {
      onAfterSubmit();
    }
  };
  return (
    <Formik<Modal>
      enableReinitialize
      initialValues={data as Modal}
      validationSchema={PartnerModalSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <S.FormWrapper>
            <S.Column>
              <SwitchField name="isActive" label="Is active" />
              <InputField name="title" label="Title" />
              <SelectField
                options={positionOptions}
                name="position"
                label="Position"
                filterable={false}
                placeholder=" "
              />
              <NumericInputField
                disabled={values.position !== PositionEnum.HOME_BANNER}
                name="order"
                label="Order"
                fill
              />
              <TextAreaField name="description" label="Description" rows={6} resize="none" />
              <InputField name="button" label="Button" />
              <InputField name="buttonLink" label="Button link" />
              <InputField name="path" label="Path" />
              <div hidden>
                <FileSelectField
                  fill
                  preView
                  name="icon"
                  label="Icon"
                  inputProps={{
                    accept: '.jpg, .jpeg, .JPG, .JPEG, .png, .PNG, .svg',
                    id: 'icon',
                    ref: iconFileInputRef,
                  }}
                  onSelect={(event, value) => {
                    setIconOptions([
                      {
                        key: value.name,
                        value,
                        caption: <IconCaption icon={value} size={24} />,
                      },
                      ...iconOptions,
                    ]);
                  }}
                />
              </div>
              <FormGroup label="Icon">
                <ControlGroup fill>
                  <SelectField<IconType>
                    fill
                    large
                    options={iconOptions}
                    name="icon"
                    filterable={false}
                    placeholder=" "
                    popoverProps={{
                      boundary: 'viewport',
                      popoverClassName: 'scrollable',
                    }}
                    inputProps={{ large: true }}
                    onCompare={(value1, value2) =>
                      ['File', 'Object'].includes(getType(value1))
                        ? (value1 as Icon)?.name === (value2 as Icon)?.name
                        : value1 === value2
                    }
                  />
                  <FormGroup style={{ flex: 0 }}>
                    <Button large icon="folder-open" onClick={() => iconFileInputRef.current?.click()} />
                  </FormGroup>
                  <FormGroup style={{ flex: 0 }}>
                    <Button large icon="trash" onClick={() => setFieldValue('icon', null)} />
                  </FormGroup>
                </ControlGroup>
                <Collapse isOpen={!!values.icon}>
                  <div style={{ paddingLeft: '15px', display: 'flex' }}>
                    {values.icon && (
                      <IconPreview
                        icon={values.icon}
                        color={((color?: ColorEnum) => (color ? colorMap[color] : color))((values.icon as Icon)?.color)}
                        size={64}
                      />
                    )}
                    {getType(values.icon) === 'Object' && (
                      <SelectField
                        label="color"
                        fill
                        filterable={false}
                        placeholder=" "
                        options={iconColorOptions}
                        name="icon[color]"
                        style={{ width: '120px', paddingLeft: '30px' }}
                      />
                    )}
                  </div>
                </Collapse>
              </FormGroup>
            </S.Column>
            <S.Column>
              <SwitchField name="appearOnEnter" label="Appear on enter" />
              <NumericInputField name="appearancesCount" label="Appearances count" fill />
              <TagInputField<number> name="clients" label="Clients" fill inputProps={{ pattern: '^\\d+$' }} />
              <MultiSelectField<string>
                name="products"
                label="Products"
                items={productsItems}
                placeholder=""
                popoverProps={{ boundary: 'viewport', position: 'right', popoverClassName: 'scrollable' }}
              />
              <MultiSelectField<string>
                name="combinations"
                label="Combinations"
                items={combinationsItems}
                placeholder=""
                popoverProps={{ boundary: 'viewport', position: 'right', popoverClassName: 'scrollable' }}
              />
              <MultiSelectField<string>
                name="combinationsPeriod"
                label="Combinations period"
                items={combinationsPeriodItems}
                placeholder=""
              />
              <MultiSelectField<string>
                name="options"
                label="Options"
                items={optionsItems}
                placeholder=""
                popoverProps={{ boundary: 'viewport', position: 'right', popoverClassName: 'scrollable' }}
              />
              <MultiSelectField<string>
                name="optionsPeriod"
                label="Options period"
                items={optionsPeriodItems}
                placeholder=""
              />
              <DateInputField
                name="dateStart"
                label="Date start"
                placeholder="YYYY-MM-DDTHH:mm"
                formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm')}
                parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm').toDate()}
                timePrecision="minute"
                fill
              />
              <DateInputField
                name="dateEnd"
                label="Date end"
                placeholder="YYYY-MM-DDTHH:mm"
                formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm')}
                parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm').toDate()}
                timePrecision="minute"
                fill
              />
              <NumericInputField name="timer" label="Timer" fill />
              <StaticSelectField url={`${API_URL_BASE_PATH}/yandex/object-storage`} name="image" label="Image" />
            </S.Column>
          </S.FormWrapper>
          <SumbitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
