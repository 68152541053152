import {
  createUseEntityList,
  createUseEntity,
  createUseEntityAdd,
  createUseEntityUpdate,
  createUseEntityDelete,
} from 'api/base-hooks';
import { partnerApi } from './partner';
import { PartnerListResponse, PartnerResponse, Partner } from './partner-types';

const SECTION = 'partner';

export const usePartnerList = createUseEntityList<PartnerListResponse>(SECTION, partnerApi.getList);
export const usePartner = createUseEntity<PartnerResponse>(SECTION, partnerApi.getItem);
export const usePartnerAdd = createUseEntityAdd<Partner>(SECTION, partnerApi.addItem);
export const usePartnerUpdate = createUseEntityUpdate<Partner>(SECTION, partnerApi.editItem);
export const usePartnerDelete = createUseEntityDelete<number>(SECTION, partnerApi.deleteItem);
